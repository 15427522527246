import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { space } from 'styled-system'

export default styled.div`
	width: 100%;
	max-width: 1200px;
	${props => props.small && 'max-width: 700px;'}
	${props => props.tiny && 'max-width: 400px;'}
	${props => props.center && 'text-align: center;'}
	margin: 0 auto;
	${props => props.spaced && css`
		@media (min-width: 832px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	`}
	${space};
`
