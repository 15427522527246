import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import GatsbyImage from "gatsby-image";
import React from "react";
import Card from "../Card";
import Kranicon from "../Kranicon";

const avatar = css`
  max-width: 90px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
`;

const Testimonials = ({ data }) => (
  <Flex flexWrap="wrap" mx={-3}>
    {data.edges.map((testimonial, index) => (
      <Box width={[1, 1 / 3]} px={3} key={index}>
        <Card collapsed>
          <div dangerouslySetInnerHTML={{ __html: testimonial.node.html }} />
        </Card>
        <Box textAlign="center" marginTop={-4} style={{ position: "relative" }}>
          {testimonial.node.frontmatter.image ? (
            <GatsbyImage
              css={avatar}
              fluid={testimonial.node.frontmatter.image.childImageSharp.fluid}
            />
          ) : (
            <Kranicon css={avatar} colored icon="kunde" />
          )}
        </Box>
        <Box textAlign="center" m={3}>
          <strong>{testimonial.node.frontmatter.author}</strong>
        </Box>
      </Box>
    ))}
  </Flex>
);

export default Testimonials;
