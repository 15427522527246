import { css } from '@emotion/react'
import styled from '@emotion/styled'

export default styled.h2`
	margin-bottom: 1.4em;
	font-size: 1.6em;
	font-weight: bold;
	${props => props.center && css`
		text-align: center;
	`}
`
