import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";
import { LinkButton } from "../Button";
import Card from "../Card";
import ProfileImage from "../ProfileImage";

const SectionAbout = () => (
  <Flex justifyContent="center">
    <Box width={[1, null, 3 / 4]} my={4} p={3}>
      <Card>
        <Flex alignItems="center" flexWrap="wrap" mx={-3}>
          <Box width={[1, 1, 0]} px={[3, 3, 0]}>
            <ProfileImage />
          </Box>
          <Box width={[1, null, 2 / 3]} px={3}>
            <h2>Linda Bäumer — Ihre Maklerin für Thüringen</h2>
            <p>
              Mein Name ist Linda Bäumer und ich bin Immobilienmakler in und für
              Thüringen. Als Makler mit langjähriger Erfahrung weiß ich, was Sie
              auf Ihrem Weg erwartet und stehe ihnen gerne fachlich und
              menschlich zu Seite.
            </p>
            <ul>
              <li>Ich bin Makler aus Leidenschaft</li>
              <li>Freundlich und warmherzig im Umgang</li>
              <li>Bezwinge jedes Chaos</li>
              <li>Seit vielen Jahren in Thüringen aktiv</li>
              <li>Schaffe Vertrauen durch Ehrlichkeit und Transparenz</li>
            </ul>
            <LinkButton to="/kontakt/">Jetzt Kontakt aufnehmen</LinkButton>
          </Box>
          <Box width={[0, 0, 1 / 3]} px={[0, 0, 3]}>
            <ProfileImage />
          </Box>
        </Flex>
      </Card>
    </Box>
  </Flex>
);

export default SectionAbout;
