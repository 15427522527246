import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import RoundedImage from "../RoundedImage";

const Name = styled.h3`
  margin-bottom: 8px;
`;

const Team = () => (
  <StaticQuery
    query={graphql`
      query TeamQuery {
        team: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/src/team/" } }
          sort: { fields: [fileAbsolutePath] }
        ) {
          edges {
            node {
              frontmatter {
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                name
                position
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Flex flexWrap="wrap" mx={-3}>
        {data.team.edges.map((member, index) => (
          <Box width={[1, 1 / 3]} px={3} pb={3} key={index}>
            <RoundedImage
              fluid={member.node.frontmatter.image.childImageSharp.fluid}
              alt={member.name}
            />
            <Box m={3}>
              <Name>{member.node.frontmatter.name}</Name>
              <div>{member.node.frontmatter.position}</div>
            </Box>
          </Box>
        ))}
      </Flex>
    )}
  />
);

export default Team;
