import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";
import { Link } from "gatsby";

const SectionLocations = () => (
  <Flex justifyContent="center">
    <Box width={[1, 1, 2 / 3, 1 / 2]} my={5} px={3}>
      <h2>Linda Bäumer ist Ihre Maklerin aus und für Thüringen</h2>
      <p>
        Kranich Immobilien ist tief in Thüringen verwurzelt. Inhaberin Linda
        Bäumer ist eine waschechte Thüringerin und arbeitet seit vielen Jahren
        als Immobilienmakler in Thüringen. Möchten Sie Ihr Haus oder Ihre
        Wohnung im schönen Freistaat verkaufen, so ist Kranich Immobilie Ihre
        beste Anlaufstelle. Zum Einzugsgebiet von Kranich Immobilien zählen
        neben der Landeshauptstadt{" "}
        <Link to="/immobilienmakler-erfurt/">Erfurt </Link>
        viele weitere Regionen, die zu den schönsten Thüringens zählen.
      </p>
      <h3>Weimar</h3>
      <p>
        Das zentral in Thüringen gelegene
        <Link to="/immobilienmakler-weimar/"> Weimar</Link> ist eine echte
        Perle. Die kreisfreie Universitätsstadt ist nicht nur wunderschön,
        sondern glänzt zusätzlich mit seinem kulturellen Erbe. Als Makler in der
        Stadt von Wieland, Goethe, Herder und Schiller tätig zu sein, ist eine
        ganz besondere Aufgabe.
      </p>
      <h3>Jena</h3>
      <p>
        Die Metropolregion Mitteldeutschland beherbergt die Universitätsstadt
        Jena. Auch wenn große Teile des historischen Stadtzentrums Jenas dem 2.
        Weltkrieg zum opfer fielen, versprüht die Thüringer Großstadt ihren
        unverkennbaren Charme. Als Technologiestandort hat Jena laut{" "}
        <a
          href="https://de.wikipedia.org/wiki/Zukunftsatlas"
          target="_blank"
          rel="noreferrer"
        >
          Zukunftsatlas 2019
        </a>{" "}
        “sehr hohen Zukunftschancen”. Jena ist für Verkäufer und Käufer beider
        Maßen höchst attraktiv.
      </p>
      <h3>Gotha</h3>
      <p>
        Als Immobilienmakler für Thüringen ist{" "}
        <Link to="/immobilienmakler-gotha/">Gotha </Link> für Kranich Immobilien
        ein wichtiger Ort. Gotha liegt etwa 20 Kilometer nördlich von Erfurt und
        50 Kilometer südlich von Leipzig. Im Dezember 2009 hatte die Stadt
        44.468 Einwohner. Der Immobilienmarkt in Gotha ist sehr aktiv und sowohl
        für Käufer als auch Verkäufer sehr spannend.
      </p>
      <h3>Weimarer Land</h3>
      <p>
        Der Landkreis{" "}
        <Link to="/immobilienmakler-weimarer-land/">Weimarer Land </Link>
        ist die Heimat von Kranich Immobilien. Er umschließt mit seiner Fläche
        von 804,48 km² die Stadt Weimar und beherbergt einige der schönsten
        Städte Thüringens. Zum Landkreis gehören bspw. die Städte{" "}
        <Link to="/immobilienmakler-apolda/">Apolda</Link>, Bad Berka,
        Blankenhain, Grammetal und Ilmtal-Weinstraße. Aber auch die
        Verwaltungsgemeinschaften Kranichfeld und Mellingen sind dem Landkreis
        untergeordnet und Einsatzgebiet von Kranich Immobilien.
      </p>
      <h3>Landkreis Saalfeld-Rudolstadt</h3>
      <p>
        Südlich des Weimarer Landes befindet sich der{" "}
        <Link to="/immobilienmakler-saalfeld-rudolstadt/">
          Landkreis Saalfeld-Rudolstadt
        </Link>
        . Hier befinden sich wunderschöne Städte und Dörfer inmitten des
        Thüringer Schiefergebirges. Bad Blankenburg,{" "}
        <Link to="/immobilienmakler-rudolstadt/">Rudolstadt</Link> sowie{" "}
        <Link to="/immobilienmakler-saalfeld/">Saalfeld</Link> sind nur einige,
        in dem Kranich Immobilien für Sie aktiv ist.
      </p>
    </Box>
  </Flex>
);

export default SectionLocations;
