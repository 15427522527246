import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";
import Container from "../Container";
import GoogleReviews from "../GoogleReviews";
import SectionTitle from "../SectionTitle";
import Testimonials from "../Testimonials";
import Tile from "../Tile";

const SectionTestimonials = ({ data, ...props }) => (
  <Tile>
    <Container spaced>
      <SectionTitle center>Das sagen meine Kunden</SectionTitle>
      <Testimonials data={data.testimonials} />
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <p>Viele weitere Stimmen zu meiner Arbeit finden Sie auf Google:</p>
        <Box width={[1, "20em"]}>
          <GoogleReviews />
        </Box>
      </Flex>
    </Container>
  </Tile>
);

export default SectionTestimonials;
