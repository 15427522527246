import { Box, Flex } from "@rebass/grid";
import React from "react";
import SectionTitle from "../SectionTitle";
import Team from "../Team";

const SectionTeam = () => (
  <Flex justifyContent="center">
    <Box width={[1, 1, 1, 2 / 3]} my={5} px={3}>
      <SectionTitle center>Das Team von Kranich Immobilien</SectionTitle>
      <Team />
    </Box>
  </Flex>
);

export default SectionTeam;
