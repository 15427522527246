import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Hide } from "@rebass/hide";

import Hero, { Intro } from "../components/Hero";
import Page from "../components/Page";

import About from "../components/SectionAbout";
import Attributes from "../components/SectionAttributes";
import Testimonials from "../components/SectionTestimonials";
import Services from "../components/SectionServices";
import Locations from "../components/SectionLocations";
import Values from "../components/SectionValues";
import Tile from "../components/Tile";
import { Flex, Box } from "@rebass/grid/emotion";
import Card from "../components/Card";
import ProfileImage from "../components/ProfileImage";
import Contact from "../components/Contact";
import PageBlock from "../components/PageBlock";
import { css } from "@emotion/react";
import PageTitle from "../components/PageTitle";
import ImmoweltBadge from "../components/ImmoweltBadge";
import GoogleReviews from "../components/GoogleReviews";
import Team from "../components/SectionTeam";

const Index = ({ data }) => (
  <Page
    header={
      <Hero fluid={data.homeHeroImage.fluid}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          css={css`
            max-width: 40em;
          `}
        >
          <PageTitle>Immobilienmakler für Thüringen</PageTitle>
          <Intro>
            Machen Sie bei der Suche nach dem besten Immobilienmakler für Ihre
            Immobilie keine Abstriche. Arbeiten Sie nur mit jemandem zusammen,
            dem Sie blind vertrauen können.
          </Intro>
          <Flex alignItems="stretch" flexWrap="nowrap">
            <Hide xsmall>
              <ImmoweltBadge />
            </Hide>
            <GoogleReviews />
          </Flex>
        </Flex>
      </Hero>
    }
  >
    <Helmet
      title="Immobilienmakler Thüringen | Linda Bäumer"
      meta={[
        {
          name: "description",
          content:
            "Sie sind auf der Suche nach dem besten Immobilienmakler in Thüringen? Mit einer Bewertung von 5 ⭐️⭐️⭐️⭐️⭐️ auf Google zählt Linda Bäumer zu den Allerbesten.",
        },
      ]}
    />
    <PageBlock>
      <About data={data} />
      <Attributes />
      <Values />
      <Services />
      <Team />
      <Testimonials data={data} />
      <Locations />
      <Tile>
        <Flex justifyContent="center">
          <Box width={[1, null, 1 / 2]}>
            <Card red>
              <ProfileImage />
              <Box mt={3}>
                <h3>Herausforderungen gemeinsam angehen</h3>
                <p>
                  Möchten Sie mit mir über Ihre Immobilie sprechen? Füllen Sie
                  dafür bitte das Formular aus. Ich melde mich danach so schnell
                  wie möglich bei Ihnen. Einfach, schnell und unverbindlich.
                </p>
                <Contact />
              </Box>
            </Card>
          </Box>
        </Flex>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    homeHeroImage: imageSharp(
      fluid: { originalName: { regex: "/home-hero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    founderImage: imageSharp(fluid: { originalName: { regex: "/linda_2/" } }) {
      fluid(maxWidth: 250, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/testimonials/" } }
      sort: { fields: [fileAbsolutePath] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 250, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
          }
          html
        }
      }
    }
  }
`;

export default Index;
