import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";
import { LinkButton } from "../Button";
import Card from "../Card";
import ProfileImage from "../ProfileImage";

const SectionValues = () => (
  <Flex justifyContent="center">
    <Box width={[1, null, 3 / 4]} mb={4} p={3}>
      <Card>
        <Flex alignItems="center" flexWrap="wrap" mx={-3}>
          <Box width={[1, 1, 0]} px={[3, 3, 0]}>
            <ProfileImage version={2} />
          </Box>
          <Box width={[1, null, 2 / 3]} px={3}>
            <h2>Gemeinsam Ihre Herausforderungen meistern</h2>
            <p>
              Als Makler hat man vielmehr mit den Menschen hinter der Immobilie
              zu tun, als man glaubt. Gerade aus diesem Grund übe ich meine{" "}
              <strong>Arbeit voller Freude</strong> aus. Man tritt mit den
              unterschiedlichsten Charakteren zusammen und kann Ihnen bei
              enormen Herausforderungen hilfreich zu Seite stehen.
            </p>
            <p>
              <strong>
                Möchten Sie mich bei Ihrer Herausforderung an Ihrer Seite haben?
              </strong>
            </p>
            <LinkButton to="/kontakt/">Ja, jetzt kontaktieren</LinkButton>
          </Box>
          <Box width={[0, 0, 1 / 3]} px={[0, 0, 3]}>
            <ProfileImage version={2} />
          </Box>
        </Flex>
      </Card>
    </Box>
  </Flex>
);

export default SectionValues;
