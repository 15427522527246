import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";

const Quote = styled.p`
  font-style: italic;
`;

const SectionAttributes = () => (
  <Flex justifyContent="center">
    <Box width={[1, 1, 2 / 3, 1 / 2]} my={5} px={3}>
      <h2>Was einen wirklich guten Makler ausmacht</h2>
      <Box mb={5} mt={4}>
        <h3>Empathie und Einsatzfreude</h3>
        <p>
          Immobilien und Menschen haben eines gemeinsam. Beide sind einzigartig.
          Für gute Immobilienmakler ist es wichtig, mit dieser Einzigartigkeit
          umgehen zu können. Anstatt Sie in einen vorgefertigten Prozess zu
          pressen, ist es die Aufgabe des Maklers, auf Ihre Wünsche und
          Anforderungen einzugehen. Ganz gleich, ob Sie eine Immobilie verkaufen
          möchte oder auf der Suche nach einem neuen zu Hause sind.
        </p>
        <Quote>
          “Damit dies gelingt, ist eine gute Atmosphäre wichtig. Die Chemie muss
          also stimmen. Dank meiner{" "}
          <strong>freundlichen und hilfsbereiten Art</strong>, schaffe ich es genau
          diese Chemie herzustellen. Mit guter Laune und{" "}
          <strong>voller Leistungsbereitschaft</strong> nehme ich für Sie jede
          Herausforderung an.”
        </Quote>
      </Box>
      <Box mb={5}>
        <h3>Kreativität und Ordnung</h3>
        <p>
          Der kompetente Immobilienmakler ist in der Lage, diesen eigentlichen
          Widerspruch aufzulösen. Bei der Vermittlung und Veräußerung ist
          Kreativität gefragt. Von der Vermarktung bis hin zur anschließenden
          Präsentation.
        </p>
        <p>
          Doch eine strukturierte Arbeitsweise ist darüber hinaus die
          Grundvoraussetzung für den Erfolg. Dem hohen Maß an Bürokratie muss
          schließlich Rechnung getragen werden. Alle Fäden fließen beim
          Immobilienunternehmen zusammen, weshalb Verwaltungsgeschick und
          Ordnung unabdingbar ist.
        </p>
        <Quote>
          “Beide Punkte habe ich gekonnt in mir vereint. Mit{" "}
          <strong>viel Liebe zum Detail</strong> lasse ich meiner Kreativität
          freien lauf. Doch am Schreibtisch werde ich zum{" "}
          <strong>Organisationstalent</strong>. Diese ungewöhnliche Kombination
          erleichtert mir meine Arbeit ungemein und führt mich und meine Kunden{" "}
          <strong>stets zum Erfolg</strong>.”
        </Quote>
      </Box>
      <Box mb={5}>
        <h3>Erfahrung - auch vom Markt vor Ort</h3>
        <p>
          Ihr Makler sollte den Markt vor Ort ganz genau kennen. Stets hat er
          die allgemeinen Entwicklungen vor Ort sowie die Preise im Blick.
        </p>
        <p>
          Ebenfalls besitzt er Kenntnisse im Miet- und Vertragsrecht. Über
          aktuelle Gesetzesänderungen und Urteile ist er ebenfalls informiert.
          In Steuer- und Finanzierungsfragen haben Sie hier bestenfalls auch
          einen kompetenten Partner zu Seite.
        </p>
        <Quote>
          “Mein Einzugsgebiet liegt ganz klar auf Thüringen. Hier kenne ich mich
          am besten aus und{" "}
          <strong>kann Ihnen somit uneingeschränkt weiterhelfen</strong>.”
        </Quote>
      </Box>
      <Box>
        <h3>Vertrauen und Transparenz</h3>
        <p>
          Vertrauen ist der Schlüssel für jede erfolgreiche Zusammenarbeit. Mit
          größtmöglicher Transparenz wird eine Vertrauensbasis geschaffen.
        </p>
        <p>
          Achten Sie darauf, dass Ihnen alle nötigen Informationen immer zur
          Verfügung gestellt werden. Im besten Fall geschieht dies von ganz
          alleine.
        </p>
        <Quote>
          “Für mich ist{" "}
          <strong>Transparenz der Grundpfeiler meiner Arbeit</strong>. Nur, wenn
          man vertrauensvoll zusammenarbeitet, ist Erfolg erst möglich. Aus
          diesem Grund halte ich meine Klienten immer auf dem Laufenden und bin
          bei meiner Beratung <strong>stets vorurteilsfrei und ehrlich</strong>
          .”
        </Quote>
      </Box>
    </Box>
  </Flex>
);

export default SectionAttributes;
